//radio btn custom css
.radio-container {
    display: block;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .radio-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0 !important;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        border: 0.5px solid #40585f;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            content: "";
            position: absolute;
            display: none;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #40585f;
        }
    }
    &:hover input ~ .checkmark,
    input:checked ~ .checkmark {
        background-color: #fff;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    input:checked ~ label {
        font-weight: 500 !important;
    }
    input:disabled ~ .checkmark,
    input:disabled ~ span.info {
        opacity: 0.3;
    }
    .radio-text {
        color: #415960;
        font-size: 12px;
        font-weight: 400;
    }
    input:checked ~ .radio-text {
        font-weight: 500;
    }
}
