.stage-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 5px;
}
.stage {
    display: flex;
    align-items: center;
    position: relative;
    img {
        height: 16px;
    }
    .date {
        color: #415960;
        font-size: 12px;
        display: block;
        margin-left: 5px;
        margin-bottom: 1px;
        white-space: nowrap;
        background: #fff;
        position: relative;
        &:nth-child(1) {
            color: #41596095;
            position: relative;
            z-index: 1;
        }
    }
    .line {
        border-top: 1.2px solid #70707050;
        width: calc(100% + 15px);
        height: 1px;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}
