.toast-container {
    right: 10px;
    top: 10px;
    .toast-success,
    .toast-error,
    .toast-info,
    .toast-warning {
        background-color: #ffffff;
    }
    .ngx-toastr {
        position: relative !important;
        overflow: hidden !important;
        margin: 6px 10px 0px auto !important;
        padding: 15px 15px 15px 50px !important;
        width: 320px !important;
        border-radius: 2px !important;
        background-position: 20px 15px !important;
        background-repeat: no-repeat !important;
        background-size: 18px !important;
        box-shadow: 1px 1px 20px #00000014 !important;
        color: #415960 !important;
        font-size: 12px;
        line-height: 19px;
        text-align: left;
        background-color: #ffffff;
        border: 0.25px solid #70707035;
        &::before {
            content: "";
            position: absolute;
            left: 2px;
            bottom: 2px;
            top: 2px;
            width: 56px;
            border-radius: 2px 0 0 2px;
        }
        &::after {
            position: absolute;
            content: "";
            width: 24px;
            height: 24px;
            left: 18px;
            background-size: 100%;
            top: 50%;
            transform: translateY(-50%);
        }
        .toast-message {
            margin-left: 18px;
        }
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    &::after {
        background-image: url("../assets/images/toastr-info.png") !important;
    }
    &::before {
        background: #417fa8;
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    &::after {
        background-image: url("../assets/images/toastr-error.png") !important;
    }
    &::before {
        background: #d15a5a;
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    &::after {
        background-image: url("../assets/images/toastr-success.png") !important;
    }
    &::before {
        background: #75a762;
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    &::after {
        background-image: url("../assets/images/toastr-warning.png") !important;
        height: 22px !important;
    }
    &::before {
        background: #e2b040;
    }
}
