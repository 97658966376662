.table-wrapper {
  height: calc(100vh - 295px);

  table {
    border: none;
    font-size: 12px;
    font-weight: 400;
    color: #415960;
    margin-bottom: 0;
    width: 100%;
    height: 100%;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border: none;

      &.active {
        background: #f7f7f7;
      }

      th,
      td {
        padding: 6px 12px;
        border-bottom: 0.5px solid #d6d6d680;

        &:first-child {
          padding-left: 0;
        }

        &.template-row {
          border-left: 0.5px solid #d6d6d680;
        }

        &.options {
          width: 15px;
          padding: 0 !important;
          padding-right: 10px !important;

          @supports (-moz-appearance: none) {
            width: 20px;
            padding-right: 15px !important;
          }
        }
      }

      &.active-full-border {
        border-radius: 5px;
        border-left: 3px solid #00aca4 !important;

        td {
          border-top: 1px solid #415960;
          border-bottom: 1px solid #415960;

          &:last-child {
            border-right: 1px solid #415960;
          }
        }
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 0;
      background: transparent;

      th {
        color: #6b6f78;
        font-weight: 300;
        font-size: 12px;
        padding-top: 0;
        background: none;
        line-height: 20px;
        padding-bottom: 2px;
        white-space: nowrap;

        &:first-child {
          padding-left: 0;
        }

        .textoverflow {
          display: inline-block;
          max-width: 85%;
          vertical-align: text-top;
        }

        &.mapping-field-head {
          border-left: 0.5px solid rgba(214, 214, 214, 0.5019607843);
        }
      }

      &.gray-bg {
        background: #4159600d;

        th {
          padding-top: 5px;
          color: #415960;
          font-weight: 500;
        }
      }
    }

    tbody {
      display: block;
      height: 100%;
      overflow-y: auto;

      @supports (-moz-appearance: none) {
        height: calc(100vh - 318px);
      }

      td {
        color: #415960;
        font-size: 12px;
        font-weight: 400;
        height: 30px;
        padding: 0 12px;

        a {
          cursor: pointer;
          color: #417fa8 !important;
          font-weight: 400 !important;
          text-decoration: none;

          &.no-click {
            pointer-events: none;
            color: #415960 !important;
          }

          .icon-right-arrow {
            display: none;
          }

          &.hover {
            &:hover {
              font-weight: 500 !important;

              .icon-right-arrow {
                font-size: 7px;
                display: inline-block;
                margin-left: 3px;
              }
            }
          }
        }

        img {
          height: 16px;

          &.lg {
            height: 23px;
          }
        }

        // dropdown inside table
        .dropdown {
          .dropdown-toggle::after {
            display: none;
          }

          i::before {
            color: #415960;
          }
        }

        // options
        .icon-options {
          &::before {
            font-size: 10px;
          }

          &:hover::after {
            width: 16px;
            height: 16px;
          }
        }

        // custom tooltip
        .custom-tooltip {
          padding: 10px;
          max-width: 365px;

          .tooltip-title {
            font-size: 12px;
            border-bottom: 0.5px solid #70707050;
            padding-bottom: 10px;
            margin-bottom: 10px;
          }

          ul {
            display: grid;
            gap: 12px;
            grid-template-columns: repeat(3, 1fr);

            li {
              text-align: center;

              label {
                //color: #6b6f78;
                color: #aaaaaa;
                margin-bottom: 2px;
                font-weight: 400;

                i {
                  font-size: 10px;
                  margin-right: 3px;
                }
              }

              .value {
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }
      }

      tr.active td {
        font-weight: 500;
      }
    }

    // alternate color for rows
    &.table-striped {
      border: 0.5px solid #d6d6d6;

      th {
        padding-top: 5px;
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: #f9f9f9;
          }

          td {
            border: none;
          }
        }
      }
    }

    // on loading table - hide the th border
    &.loading {
      th {
        border: none;
        white-space: inherit;
      }
    }
  }

  .header-border {
    border-top: 0.5px solid #d6d6d680;
  }

  .header-bg {
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .border-right {
    border-right: 0.5px solid #d6d6d680 !important;
  }

  .has-scroll {
    position: relative;
    right: 3px;
  }
}
