.dropdown {
    float: right;
    color: #415960;

    .dropdown-toggle {
        font-size: 11px;
        font-weight: 500;
        cursor: pointer;
        &::after {
            border-top: 4px solid;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            margin-left: 8px;
        }

        &.show {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .dropdown-menu {
        border: none;
        box-shadow: 1px 1px 20px #00000014;
        min-width: unset;
        height: fit-content;
        &.menu-align-right {
            left: -25px !important;
        }
        .dropdown-item {
            font-size: 11px;
            font-weight: 400;
            cursor: pointer;
            color: #415960 !important;
            padding-bottom: 2px;
            line-height: 16px;
            i {
                display: none;
                float: right;
                &::before {
                    color: #415960;
                }
                &.icon-pencil {
                    font-size: 9px;
                }
                &.icon-tick::before {
                    font-size: 8px;
                }
            }
            &:hover {
                background: none;
            }
            &.active {
                font-weight: 500;
                background-color: transparent;
                pointer-events: none;
                i {
                    display: inline-block;
                }
            }
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
        &.options-menu {
            top: -14px !important;
            right: 12px !important;
            .dropdown-item {
                i {
                    float: none;
                    display: inline-block;
                    margin-right: 5px;
                    &::before {
                        font-size: unset;
                    }
                }
            }
        }
    }
    &.no-arrow {
        .dropdown-toggle::after {
            display: none;
        }
    }
}
