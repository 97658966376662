.form {
  overflow-y: auto;
  width: 100%;

  .grouped-form {
    .form-title {
      background: #f4f6f6;
      font-weight: 500;
      font-size: 12px;
      color: #415960;
      padding: 3.5px 10px;
      margin-bottom: 12px;
      height: 24px;
    }
  }

  .form-group {
    margin-bottom: 15px;
    vertical-align: text-top;
    position: relative;

    label {
      color: #415960;
      font-size: 12px;
      font-weight: 300;
      display: block;
      margin-bottom: 0;
    }

    .value {
      color: #415960;
      font-size: 12px;
      font-weight: 400;
    }

    input,
    textarea {
      border: 0.5px solid #9fabae;
      padding: 0 8.5px;
      vertical-align: top;
    }

    .days {
      color: #767b87;
      font-size: 11px;
      font-weight: 300;
      position: absolute;
      top: 19px;
      vertical-align: middle;
      &.edit {
        right: 5px;
        top: 22px;
      }
    }

    .upload-wrapper {
      background: #f4f4f480;
      border-radius: 2px;
      padding: 8px 6px;
    }

    .upload-section {
      position: relative;
      border: 0.5px dashed #9fabae;
      text-align: center;
      clear: both;
      background: #fff;
      height: 75px;

      img {
        max-height: 60px;
      }
    }

    &.lg {
      width: 64.6%;
      display: inline-block;
    }

    &.md {
      width: 47.5%;
      display: inline-block;
      margin-right: 20px;
      &:nth-child(even) {
        margin-right: 0 !important;
      }
    }

    &.sm {
      width: 31.9%;
      display: inline-block;
      margin-right: 20px;

      @media screen and (max-width: 1165px) {
        width: 31.7%;
      }
    }

    &.col-5 {
      width: 18.9%;
      display: inline-block;
      margin-right: 11px;

      @media screen and (max-width: 1165px) {
        width: 18.8%;
      }
    }

    &.xs {
      width: 15.3%;
      display: inline-block;
      margin-right: 15px;
      &:nth-of-type(7) {
        margin-right: 0;
      }
      @media screen and (max-width: 1165px) {
        width: 15.1%;
      }
    }

    &.full-border {
      border: 0.5px dotted #9fabae;
      padding: 10px;
      border-radius: 5px;

      label {
        background: #fff;
        position: absolute;
        top: -8px;
        padding: 0 5px;
      }
    }

    &:last-child {
      margin-right: 0 !important;
    }

    .icon-search {
      position: absolute;
      font-size: 9px;
      top: 26px;
      left: 8px;
    }
  }

  .added-link-info {
    color: #767b87;
    font-size: 11px;
    font-weight: 300;
  }

  // detail page
  &.view-detail {
    .form-group {
      width: 31.5%;
    }

    &.view {
      input,
      .asterik,
      .ng-select {
        display: none;
      }
    }
  }
}
