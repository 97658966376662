.card {
  background: #fff;
  box-shadow: 0 3px 6px #00000007;
  border-radius: 5px;
  border: none;
  padding: 20px;
  position: absolute;
  top: 132px;
  left: 27px;
  right: 27px;
  bottom: 36px;

  &.sm {
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: unset;
  }

  .card-header {
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px 20px 6px 20px;
    box-shadow: 0 0 18px #00000016;
    height: 56px;
    .card-title {
      color: #415960;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      line-height: 21px;
      margin-right: 12px;
      max-width: 93%;
    }
    .sub-title {
      font-size: 12px;
      color: #415960;
      font-weight: 400;
      margin-top: -2px;
    }
    .btn-link {
      margin-top: 1px;
    }
    .total {
      color: #415960;
      font-size: 12px;
      font-weight: 400;
    }
    .card-actions {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }
    .icon-close {
      font-weight: bolder;
      font-size: 12px;
    }
  }

  form {
    margin-top: 30px;
  }

  .table-wrapper {
    margin-top: 45px;
  }

  .card-body {
    padding: 0;
    border: 0.5px solid #70707050;
    border-radius: 5px;
    margin-top: 22px;
    margin-bottom: 30px;
    height: calc(100% - 80px);
    .form {
      margin-top: 10px;
      width: calc(100% - 240px);
      padding-right: 10px;
    }
  }

  .card-footer {
    box-shadow: 0 0 18px #00000016;
    height: 48px;
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border: none;
    display: flex;
    align-items: center;
    .dropdown {
      border: 0.5px solid #9fabae;
      border-radius: 3px;
      padding: 0 10px;
      line-height: 26px;
      height: 26px;
      .dropdown-toggle {
        display: flex;
        align-items: center;
      }
      .dropdown-menu {
        transform: translate3d(0px, -36px, 0px) !important;
        &::after {
          content: "";
          position: absolute;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #fff;
          bottom: -6px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .btn-reset {
    padding: 5px 10px;
    border: 0.5px solid #00aca4 !important;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    height: 26px;
    font-weight: 400;
    font-size: 12px;
    color: #407ea7 !important;
    img {
      height: 14px;
    }
  }

  &.card-detail {
    position: absolute;
    left: -5px;
    right: -5px;
    top: 5px;
    bottom: -5px;
    z-index: 2;
    .card-title {
      font-size: 16px;
    }
    .card-body {
      &.no-border {
        border: none;
      }
      .form {
        margin-top: 10px;
        width: calc(100% - 240px);
        padding-right: 10px;
      }
    }
  }

  // inline card styles
  &.card-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 465px;
    box-shadow: 1px 1px 20px #00000014;
    left: unset;
    z-index: 1;
    &.edit-flow::before,
    &.restrict-bg::before {
      content: "";
      position: fixed;
      right: 10px;
      left: 27px;
      bottom: 42px;
      top: 140px;
      z-index: -1;
    }
    &.md {
      width: 65%;
    }
    .card-header {
      .card-title {
        font-size: 16px;
        line-height: 18px;
      }
      .count-info {
        font-size: 12px;
        margin-top: 2px;
        font-weight: 300;
        color: #415960;
      }
    }
    .card-footer {
      padding: 9px 20px;
    }
  }
}
