.iti {
    .iti__flag-container {
        width: 38px;
        .iti__selected-flag {
            padding: 0;
        }
        .iti__flag {
            display: none;
        }
        .selected-dial-code {
            font-size: 12px;
            color: #415960;
            margin: 0 !important;
        }
    }
    input {
        padding-left: 40px !important;
    }
}
