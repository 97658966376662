.calendar {
    position: absolute;
    width: 302px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    cursor: default;

    .calendar-header {
        position: absolute;
        z-index: 2;
        top: 15px;
        width: 100%;
        .right-arrow,
        .left-arrow {
            cursor: pointer;
            display: inline-block;
        }
        .right-arrow {
            border-left: 5px solid #006fa8;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            margin-left: 10px;
        }
        .left-arrow {
            border-right: 5px solid #006fa8;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            margin-right: 10px;
        }
    }
    .months {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: row;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 1px solid #70707050;
        border-bottom: 1px solid #70707050;
        padding: 7px 0;
        width: 302px;
        justify-content: space-evenly;
        .month-item {
            font-size: 11px;
            color: #415960;
            font-weight: 300;
            line-height: 13px;
            .disabled {
                opacity: 0.7;
            }
            &.active {
                background: #00aca4;
                color: #fff;
                font-weight: 400;
                height: 14px;
                padding: 1px 4px;
                border-radius: 2px;
            }
        }
    }
}

ngb-datepicker {
    position: absolute;
    background: #fff;
    z-index: 1;
    border: none;
    box-shadow: 0 2px 5px #00000026;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    .form-select,
    .ngb-dp-arrow {
        display: none;
    }
    .ngb-dp-header {
        background: #fff;
        height: 65px;
    }
    .ngb-dp-content {
        .ngb-dp-day,
        .ngb-dp-weekday,
        .ngb-dp-week-number {
            margin: 0 5px;
        }
        .ngb-dp-weekdays {
            background: #fff;
            border: none;
            .ngb-dp-weekday {
                color: #415960;
                font-style: normal;
                font-weight: 300;
                font-size: 10px;
            }
        }
        .btn-light {
            font-size: 12px;
            color: #415960 !important;
            font-weight: 400;
            width: 100%;
            &.text-muted {
                pointer-events: none;
            }
            &.bg-primary {
                background-color: #00aca4 !important;
                color: #fff !important;
            }
        }
        .ngb-dp-day {
            cursor: default !important;
            color: #415960;
            margin-bottom: 4px;
            span {
                cursor: pointer;
                line-height: 32px;
                &.disabled {
                    cursor: default !important;
                }
            }
            .highlighted {
                background: #dddddd40;
                border: 0.25px solid #70707030;
                color: #415960 !important;
                opacity: 1;
                border-radius: 2px;
                pointer-events: auto;
                cursor: pointer;
                width: 100%;
                display: inline-block;
                height: 100%;
                .btn-light {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
            .selected {
                background-color: #00aca4;
                color: #ffffff !important;
            }
        }
    }
}
