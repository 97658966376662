@import "./variables";

@font-face {
  font-family: "cobalt";
  src: url("../assets/fonts/cobalt.eot?4awm6d");
  src:
    url("../assets/fonts/cobalt.eot?4awm6d#iefix") format("embedded-opentype"),
    url("../assets/fonts/cobalt.ttf?4awm6d") format("truetype"),
    url("../assets/fonts/cobalt.woff?4awm6d") format("woff"),
    url("../assets/fonts/cobalt.svg?4awm6d#cobalt") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "cobalt", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-grey-smiley {
  font-size: 16px;

  &.path1 {
    &:before {
      content: $icon-grey-smiley-path1;
      color: #41596080;
      opacity: 0.998;
    }
  }
}

.icon-grey-smiley .path2 {
  &:before {
    content: $icon-grey-smiley-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
    opacity: 0.996;
  }
}

.icon-grey-smiley .path3 {
  &:before {
    content: $icon-grey-smiley-path3;
    margin-left: -1em;
    color: #41596080;
    opacity: 0.998;
  }
}

.icon-grey-smiley .path4 {
  &:before {
    content: $icon-grey-smiley-path4;
    margin-left: -1em;
    color: rgb(216, 216, 216);
    opacity: 0.998;
  }
}

.icon-grey-smiley .path5 {
  &:before {
    content: $icon-grey-smiley-path5;
    margin-left: -1em;
    color: rgb(216, 216, 216);
    opacity: 0.998;
  }
}

.icon-grey-smiley .path6 {
  &:before {
    content: $icon-grey-smiley-path6;
    margin-left: -1em;
    color: rgb(216, 216, 216);
    opacity: 0.998;
  }
}

.icon-green-smiley {
  font-size: 16px;

  &.path1 {
    &:before {
      content: $icon-green-smiley-path1;
      color: #415960;
      opacity: 0.004;
    }
  }
}

.icon-green-smiley .path2 {
  &:before {
    content: $icon-green-smiley-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
    opacity: 0.996;
  }
}

.icon-green-smiley .path3 {
  &:before {
    content: $icon-green-smiley-path3;
    margin-left: -1em;
    color: rgb(117, 167, 98);
    opacity: 0.998;
  }
}

.icon-green-smiley .path4 {
  &:before {
    content: $icon-green-smiley-path4;
    margin-left: -1em;
    color: rgb(117, 167, 98);
    opacity: 0.998;
  }
}

.icon-green-smiley .path5 {
  &:before {
    content: $icon-green-smiley-path5;
    margin-left: -1em;
    color: rgb(117, 167, 98);
    opacity: 0.998;
  }
}

.icon-green-smiley .path6 {
  &:before {
    content: $icon-green-smiley-path6;
    margin-left: -1em;
    color: rgb(117, 167, 98);
    opacity: 0.998;
  }
}

.icon-red-smiley {
  font-size: 16px;

  &.path1 {
    &:before {
      content: $icon-red-smiley-path1;
      color: rgb(255, 255, 255);
      opacity: 0.996;
    }
  }
}

.icon-red-smiley .path2 {
  &:before {
    content: $icon-red-smiley-path2;
    margin-left: -1em;
    color: rgb(196, 100, 72);
  }
}

.icon-red-smiley .path3 {
  &:before {
    content: $icon-red-smiley-path3;
    margin-left: -1em;
    color: rgb(196, 100, 72);
  }
}

.icon-red-smiley .path4 {
  &:before {
    content: $icon-red-smiley-path4;
    margin-left: -1em;
    color: rgb(196, 100, 72);
  }
}

.icon-red-smiley .path5 {
  &:before {
    content: $icon-red-smiley-path5;
    margin-left: -1em;
    color: rgb(196, 100, 72);
  }
}

.icon-yellow-smiley {
  font-size: 16px;

  &.path1 {
    &:before {
      content: $icon-yellow-smiley-path1;
      color: rgb(255, 255, 255);
      opacity: 0.996;
    }
  }
}

.icon-yellow-smiley .path2 {
  &:before {
    content: $icon-yellow-smiley-path2;
    margin-left: -1em;
    color: rgb(226, 158, 6);
  }
}

.icon-yellow-smiley .path3 {
  &:before {
    content: $icon-yellow-smiley-path3;
    margin-left: -1em;
    color: rgb(226, 158, 6);
  }
}

.icon-yellow-smiley .path4 {
  &:before {
    content: $icon-yellow-smiley-path4;
    margin-left: -1em;
    color: rgb(226, 158, 6);
  }
}

.icon-yellow-smiley .path5 {
  &:before {
    content: $icon-yellow-smiley-path5;
    margin-left: -1em;
    color: rgb(226, 158, 6);
  }
}

.icon-business {
  &:before {
    content: $icon-business;
    color: #415960;
  }
}

.icon-admin {
  &:before {
    content: $icon-admin;
    color: #415960;
  }
}

.icon-master-data {
  &:before {
    content: $icon-master-data;
    color: #415960;
  }
}

.icon-completed .path1 {
  &:before {
    content: $icon-completed-path1;
    color: rgb(237, 238, 237);
  }

  &.lg::before {
    font-size: 23px;
  }
}

.icon-completed .path2 {
  &:before {
    content: $icon-completed-path2;
    margin-left: -1em;
    color: rgb(117, 165, 101);
  }

  &.lg::before {
    font-size: 23px;
  }
}

.icon-closed .path1 {
  &:before {
    content: $icon-closed-path1;
    color: rgb(250, 250, 250);
  }
}

.icon-closed .path2 {
  &:before {
    content: $icon-closed-path2;
    margin-left: -1em;
    color: rgb(172, 172, 172);
  }
}

.icon-calendar {
  &:before {
    content: $icon-calendar;
    color: #415960;
  }
}

.icon-net-pay {
  &:before {
    content: $icon-net-pay;
    color: #415960;
  }
}

.icon-workflow {
  &:before {
    content: $icon-workflow;
    color: #40585f;
  }
}

.icon-world {
  &:before {
    content: $icon-world;
    color: #415960;
  }
}

.icon-circle-tick {
  &:before {
    content: $icon-circle-tick;
    color: #b7b7b7;
  }
}

.icon-solid-tick .path1 {
  &:before {
    content: $icon-solid-tick-path1;
    color: rgb(0, 111, 168);
  }
}

.icon-solid-tick .path2 {
  &:before {
    content: $icon-solid-tick-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-user-girl .path1 {
  &:before {
    content: $icon-user-girl-path1;
    color: rgb(236, 239, 241);
  }
}

.icon-user-girl .path2 {
  &:before {
    content: $icon-user-girl-path2;
    margin-left: -1em;
    color: rgb(69, 90, 100);
  }
}

.icon-user-profile .path1 {
  &:before {
    content: $icon-user-profile-path1;
    color: rgb(236, 239, 241);
  }
}

.icon-user-profile .path2 {
  &:before {
    content: $icon-user-profile-path2;
    margin-left: -1em;
    color: rgb(65, 89, 96);
  }
}

.icon-viewer {
  &:before {
    content: $icon-viewer;
    color: #00aca4;
  }
}

.icon-documents {
  &:before {
    content: $icon-documents;
    color: #40585f;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
    color: #415960;
  }
}

.icon-send {
  &:before {
    content: $icon-send;
    color: #417fa8;
  }
}

.icon-sort {
  &:before {
    content: $icon-sort;
    color: #41596080;
  }
}

.icon-sort-asc .path1 {
  &:before {
    content: $icon-sort-asc-path1;
    color: rgb(63, 87, 94);
  }
}

.icon-sort-asc .path2 {
  &:before {
    content: $icon-sort-asc-path2;
    margin-left: -1.2001953125em;
    color: rgb(51, 51, 51);
    opacity: 0.4;
  }
}

.icon-sort-desc .path1 {
  &:before {
    content: $icon-sort-desc-path1;
    color: rgb(51, 51, 51);
    opacity: 0.4;
  }
}

.icon-sort-desc .path2 {
  &:before {
    content: $icon-sort-desc-path2;
    margin-left: -1.2001953125em;
    color: rgb(63, 87, 94);
    opacity: 0.996;
  }
}

.icon-statistics {
  &:before {
    content: $icon-statistics;
    color: #415960;
  }
}

.icon-right-arrow {
  &:before {
    content: $icon-right-arrow;
    color: #00aca4;
  }
}

.icon-run {
  &:before {
    content: $icon-run;
    color: #9a9898;
  }
}

.icon-entities {
  &:before {
    content: $icon-entities;
    color: #415960;
  }

  &.teal::before {
    color: #00aca4;
  }
}

.icon-national-id {
  &:before {
    content: $icon-national-id;
    color: #415960;
  }
}

.icon-open-lock {
  &:before {
    content: $icon-open-lock;
    color: #9a9898;
  }
}

.icon-padlock {
  &:before {
    content: $icon-padlock;
    color: #cbcbcb;
  }
}

.icon-pencil {
  &:before {
    content: $icon-pencil;
    color: #415960;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
    color: #415960;
  }

  &.teal::before {
    color: #00aea6;
  }
}

.icon-plus {
  &:before {
    content: $icon-plus;
    color: #00aea6;
  }
}

.icon-tick {
  &:before {
    content: $icon-tick;
    color: #75a762;
  }
}

.icon-gross-pay {
  &:before {
    content: $icon-gross-pay;
    color: #415960;
  }
}

.icon-home {
  &:before {
    content: $icon-home;
    color: #417fa8;
  }
}

.icon-info {
  &:before {
    content: $icon-info;
    color: #0073ae;
  }
}

.icon-job-band {
  &:before {
    content: $icon-job-band;
    color: #415960;
  }
}

.icon-job-detail {
  &:before {
    content: $icon-job-detail;
    color: #455a64;
  }
}

.icon-link {
  &:before {
    content: $icon-link;
    color: #00aca4;
  }
}

.icon-logout {
  &:before {
    content: $icon-logout;
    color: #00b2a9;
  }
}

.icon-logout-hover {
  &:before {
    content: $icon-logout-hover;
    color: #417fa8;
  }
}

.icon-options {
  cursor: pointer;
  position: relative;

  &::before {
    content: $icon-options;
    color: #415960 !important;
    font-size: 13px;
  }

  &:hover {
    &:after {
      content: "";
      border-radius: 50%;
      width: 22px;
      height: 22px;
      background: #00000012;
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.icon-chat {
  &:before {
    content: $icon-chat;
    color: #fff;
  }
}

.icon-chat-active .path1 {
  &:before {
    content: $icon-chat-active-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-chat-active .path2 {
  &:before {
    content: $icon-chat-active-path2;
    margin-left: -1.06640625em;
    color: rgb(0, 172, 164);
  }
}

.icon-chat-active .path3 {
  &:before {
    content: $icon-chat-active-path3;
    margin-left: -1.06640625em;
    color: rgb(0, 172, 164);
  }
}

.icon-chat-active .path4 {
  &:before {
    content: $icon-chat-active-path4;
    margin-left: -1.06640625em;
    color: rgb(0, 172, 164);
  }
}

.icon-circle-close {
  &:before {
    content: $icon-circle-close;
    color: #4281aa;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
    color: #415960;
  }

  &.close-red::before {
    color: #c46448;
  }
}

.icon-create {
  &:before {
    content: $icon-create;
    color: #00aca4;
  }
}

.icon-domino {
  &:before {
    content: $icon-domino;
    color: #00aea6;
  }
}

.icon-flash {
  &:before {
    content: $icon-flash;
    color: #00aca4;
  }
}

.icon-flash-active {
  &:before {
    content: $icon-flash-active;
    color: #fff;
  }
}

.icon-solid-minus .path1 {
  &:before {
    content: $icon-solid-minus-path1;
    color: #00b2a9;
  }
}

.icon-solid-minus .path2 {
  &:before {
    content: $icon-solid-minus-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-export {
  &:before {
    content: $icon-export;
    color: #3f575e;
  }
}

.icon-family {
  &:before {
    content: $icon-family;
    color: #415960;
  }
}

.icon-gear {
  &:before {
    content: $icon-gear;
    color: #00b2a9;
  }
}

.icon-group {
  &:before {
    content: $icon-group;
    color: #417fa8;
  }
}

.icon-save {
  &:before {
    content: $icon-save;
    color: #fff;
  }

  &.teal::before {
    color: #00aca4;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
    color: #415960;
  }
}

.icon-error-alert {
  &:before {
    content: $icon-error-alert;
    color: #d15a5a;
  }
}

.icon-copy {
  &:before {
    content: $icon-copy;
    color: #3f575e;
  }
}

.icon-down-arrow {
  &:before {
    content: $icon-down-arrow;
    color: #415960;
  }
}

.icon-briefcase {
  &:before {
    content: $icon-briefcase;
    color: #9a9898;
  }
}

.icon-clipboard {
  &:before {
    content: $icon-clipboard;
    color: #3f575e;
  }
}

.icon-clock {
  &:before {
    content: $icon-clock;
    color: #00aca4;
  }
}

.icon-address {
  &:before {
    content: $icon-address;
    color: #415960;
  }
}

.icon-arrow {
  &:before {
    content: $icon-arrow;
    color: #00b2a9;
  }
}

.icon-avatar {
  &:before {
    content: $icon-avatar;
    color: #415960;
  }
}

.icon-bank {
  &:before {
    content: $icon-bank;
    color: #415960;
  }
}

.icon-bin {
  &:before {
    content: $icon-bin;
    color: #3f7da5;
  }

  &.red::before {
    color: #c46448;
  }

  &.teal::before {
    color: #00aca4;
  }
}

.icon-bin-disabled {
  &:before {
    content: $icon-bin;
  }
}