.nav {
  border: 0.5px solid #cccccc;
  border-radius: 2px;
  width: fit-content;

  .nav-item {
    display: flex;
    align-items: center;

    &:first-child {
      .active {
        border-radius: 1px 0px 0px 1px;
      }
    }

    &:last-child {
      .active {
        border-radius: 0px 1px 1px 0px;
      }
    }

    .nav-link {
      padding: 8px 20px;
      color: #417fa8;
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;



      &.active {
        color: #fff;
        background: #00ACA4;
        font-weight: 500;
        pointer-events: none;
      }


    }
  }

  &.nav-secondary {
    border: none;
    margin-top: 40px;

    .nav-item {
      .nav-link {
        &:first-child {
          padding-left: 0;
        }

        color: #417fa8;

        &.active {
          background: none;
          color: #415960;
        }
      }
    }
  }
}