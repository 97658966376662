input[type="date"]::before {
    content: "DD MMM YYYY";
    display: block;
    width: 100px;
    position: absolute;
    background: #fff;
    height: 18px;
    top: 3.5px;
    left: 6px;
    font-size: 12px;
    color: #415960;
    font-weight: 300;
    @media screen and (max-width: 1165px) {
        font-size: 11px;
        left: 4px;
    }
}
input[type="date"]::after {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
    width: 12px;
    height: 12px;
    right: 10px;
    top: 6px;
}
.form-group input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: 5px;
    position: relative;
    width: 100%;
    &.has-value {
        &::before {
            display: none;
        }
    }
}
