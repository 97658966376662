.grid-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  gap: 10px;
  padding: 16px 7px 10px 0;
  overflow-y: auto;
  height: 100%;
  .grid {
    color: #415960;
    border: 0.5px solid #41596050;
    padding: 5.5px 12px 13px 15px;
    border-radius: 5px;
    position: relative;
    max-height: 100px;
    height: fit-content;
    min-height: 100px;
    min-width: -webkit-fill-available;
    .icon-user-profile {
      font-size: 32px;
      margin-right: 9px;
      margin-top: 5px;
    }
    .width-fill {
      width: -webkit-fill-available;
      height: 90px;
    }
    h6 {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 2px;
      display: inline-block;
    }
    .icon-options {
      float: right;
      margin-top: 5px;
      &::before {
        font-size: 10px;
      }
      &:hover::after {
        width: 16px;
        height: 16px;
        top: -3px;
      }
    }
    .dropdown {
      .dropdown-menu {
        right: 8px !important;
        left: unset !important;
        top: -2px !important;
      }
    }
    h4 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 2px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
    }
    .small {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      line-height: 6px;
    }
    &.grid-new {
      border: 0.5px dashed #41596050;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .grid-footer {
      margin-top: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 10px 7px 15px;
    }
    .create-new {
      position: absolute;
      border: 0.5px solid #415960;
      border-radius: 5px;
      left: -0.5px;
      top: -1px;
      background: #fff;
      min-width: calc(100% + 1.5px);
      overflow-y: auto;
      // max-height: calc(100vh - 288px);
      z-index: 1;
      box-shadow: 0 3px 6px #00000029;
      &::before {
        background: rgba(255, 255, 255, 0.4);
        content: "";
        position: fixed;
        top: 140px;
        bottom: 40px;
        left: 22px;
        right: 22px;
      }
      &.double-width {
        width: calc(200% + 14px);
      }
      &.triple-width {
        width: calc(300% + 22px);
        .form-group.sm {
          margin-right: 10px;
        }
      }
      .form {
        padding: 15px 12px 2px 12px;
        max-height: calc(100vh - 385px);
        @media screen and (max-width: 1165px) {
          padding: 8px 12px 2px 12px;
        }
        .form-group {
          margin-bottom: 11px;
          @media screen and (max-width: 1165px) {
            margin-bottom: 9px;
          }
          &.md {
            width: calc(50% - 8px);
            margin-right: 16px;
          }
        }
      }
      .form-footer {
        padding: 5px 0;
        box-shadow: 0 0 18px #00000016;
        position: relative;
        z-index: 0;
        .icon-circle-close::before {
          color: #c46448;
        }
      }
    }

    &.grid-bg {
      background: #f4f4f480;
      border-radius: 2px;
      height: 55px;
      min-height: 55px;
      padding: 0 8px;
      h6 {
        font-size: 12px;
      }
    }

    &:nth-child(4n) {
      .create-new {
        left: unset;
        right: 0.5px;
      }
    }
  }

  &.view-detail {
    grid-template-columns: repeat(3, 1fr);
    .grid {
      &:nth-child(3n) {
        .create-new {
          left: unset;
          right: 0.5px;
        }
      }
      &:nth-child(4n) {
        .create-new {
          left: 0.5px;
          right: unset;
        }
      }
    }
  }
}
