button {
    outline: none;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    background: none;
    text-decoration: none !important;
    height: 30px;

    i,
    img {
        margin-right: 4px;
    }

    &.btn-primary {
        background: #006fa8;
        border: 0.5px solid #006fa8;
        padding: 5px 15px;
        color: #fff;
        margin-right: 16px;

        &.btn-dark {
            background: #415960;
            color: #fff;
            border: 0.5px solid #415960;
            padding: 4px 14px;
        }

        &.btn-teal {
            background: #00aca4;
            border: 0.5px solid #00aca4;
        }

        i::before {
            color: #fff;
        }
    }

    &.btn-outline {
        padding: 5px 15px;
        border: 1px solid #407ea7;
        color: #407ea7 !important;

        &.btn-dark-outline {
            padding: 4px 14px;
            border: 1px solid #415960;
            color: #415960 !important;
        }

        &.btn-light-outline {
            border: 1px solid #fff;
            color: #fff !important;

            i::before {
                color: #fff;
            }
        }
    }

    &.btn-link {
        color: #417fa8;
        font-size: 12px;
        max-height: 23px;

        &:hover {
            color: #417fa8;
        }

        i:before {
            color: #00aca4;
        }

        &.teal {
            color: #00aca4;
        }
    }

    &.btn-danger {
        background: #c36363;
        border: 0.5px solid #c36363;
        padding: 5px 27px;
        color: #fff;
        margin-right: 16px;

        i:before {
            color: #fff;
        }
    }

    &.sm {
        font-size: 12px;
        border-radius: 3px;
        padding: 4px 10px;
        height: 26px;
    }
}