.popup-wrapper {
    background: #fff;
    position: absolute;
    box-shadow: 2px 3px 50px #00000026;
    border-radius: 2px;
    padding: 12px;
    border: 0.25px solid #d5d5d570;
    z-index: 2;
    top: 10px;
    border-bottom: none;
    .popup-title {
        color: #415960;
        font-size: 14px;
        font-weight: 500;
    }
    .popup {
        top: 42px;
        left: -0.5px;
        padding-bottom: 20px;
        width: 300px;
        label {
            display: block;
            color: #415960;
            font-size: 12px;
            font-weight: 500;
        }
        &::before {
            content: "";
            position: absolute;
            height: 1px;
            width: 135px;
            top: -1px;
            background: #fff;
            left: 0;
        }
    }
}

.popup {
    position: absolute;
    width: auto;
    background: #fff;
    padding: 10px;
    box-shadow: 2px 32px 50px #00000026;
    border: 0.25px solid #d5d5d570;
    &.arrow {
        &::before {
            content: "";
            position: absolute;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 7px solid #fff;
            right: -6px;
            filter: drop-shadow(2px 4px 10px #00000050);
        }
    }
    &.arrow-top {
        &::before {
            transform: rotate(-90deg);
            top: -10px;
        }
    }
}
