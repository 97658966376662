.multiselect-dropdown {
  display: inline-block;
  z-index: 1;
  .disabled {
    opacity: 0.8;
  }
  .dropdown-btn {
    padding: 2px 12px !important;
    min-width: 122px !important;
    border: none !important;
    height: 30px;
    span:first-child {
      font-size: 12px;
      color: #415960;
      font-weight: 300;
      vertical-align: baseline;
      display: inline-block;
    }
    span {
      margin-top: -1px;
      span {
        font-weight: 400 !important;
        font-size: 10px !important;
        padding-right: 8px !important;
        color: #417fa8 !important;
      }
    }
    .dropdown-multiselect__caret {
      height: 27px !important;
      width: 25px !important;
      top: -0.5px !important;
      &:before {
        border-width: 4px 4px 0 4px !important;
        border-color: #415960 transparent !important;
        top: inherit !important;
        right: inherit !important;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .dropdown-multiselect--active {
      height: 26px;
      margin-top: -2px;
      span {
        font-size: 10px;
        padding-right: 8px !important;
        font-weight: 400 !important;
      }
      .dropdown-multiselect__caret {
        &:before {
          margin-top: 0 !important;
        }
      }
    }
    .selected-item-container {
      margin-top: 4.8px;
      .selected-item {
        background: #415960 !important;
        border: 1px solid #415960 !important;
        white-space: nowrap;
        display: flex;
        align-items: flex-end;
        margin-right: 12px !important;
        a {
          display: none;
        }
        &:hover {
          box-shadow: none !important;
        }
        span {
          color: #fff !important;
          vertical-align: bottom;
          width: 90%;
        }
      }
    }
  }
  .dropdown-list {
    box-shadow: 1px 1px 20px #00000014 !important;
    border: none !important;
    padding-top: 0 !important;
    overflow: hidden;
    margin-top: 1px !important;
    .item1,
    .item2 {
      .multiselect-item-checkbox {
        input[type="checkbox"] + div:before {
          border: 0.5px solid #9fabae !important;
          border-radius: 2px;
          width: 14px !important;
          height: 14px !important;
          top: 8px !important;
        }
        input[type="checkbox"] + div:after {
          left: 2.5px !important;
          top: 7px !important;
          border-width: 0 0 1.5px 1.5px !important;
        }

        input[type="checkbox"]:disabled + div::before {
          border-color: #ccc !important;
          background: #eee;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
    .item1 {
      background: #f4f4f475;
      padding: 0 !important;
      overflow: hidden !important;
      border: 0.5px solid #70707050;
      border-radius: 3px 3px 0 0;
      max-height: 40px;
      .multiselect-item-checkbox {
        height: 35px;
        border: none !important;
        input[type="checkbox"]:checked + div:before {
          background: #40585f !important;
          border: 0.5px solid #40585f !important;
        }
        input[type="checkbox"] + div {
          font-weight: 500;
          position: absolute;
          right: 12px;
          top: -7px;
          left: -11px;
          padding: 0 12px;
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          margin: 0;
          color: #417fa8 !important;
          font-size: 10px;
          text-align: left;
          &::before {
            left: 11px !important;
            top: 18px !important;
          }
          &::after {
            left: 13.5px !important;
            top: 17px !important;
          }
        }
      }
    }
    .item2 {
      input[type="checkbox"]:checked + div:before {
        background: #fff !important;
      }
      input[type="checkbox"]:checked + div:after {
        border-color: #40585f !important;
      }
      input[type="checkbox"] + div {
        font-size: 11px;
        color: #415960 !important;
        font-weight: 400;
        word-break: break-all;
      }
      input[type="checkbox"]:checked + div {
        font-weight: 500;
        color: #415960;
      }
    }
    .filter-textbox {
      border-bottom: none !important;
      top: -29px;
      left: 35px;
      right: 62px;
      position: relative !important;
      padding: 0 !important;
      input {
        background: transparent;
        padding-left: 0 !important;
        height: 30px;
      }
      width: 50%;
    }
    .no-filtered-data,
    .no-data {
      h5 {
        font-size: 12px;
        line-height: 30px;
        font-weight: 300;
        text-align: center;
        color: #415960;
        margin-bottom: 0;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}

// to open upwards
.position-top {
  .multiselect-dropdown {
    .dropdown-list {
      top: auto;
      bottom: 100%;
    }
  }
}

// multi select with border
.full-border {
  .multiselect-dropdown {
    .dropdown-btn {
      border: 0.5px solid #9fabae !important;
      border-radius: 2px !important;
    }
  }
}

// single select - hide top list having select all checkbox
.single-select {
  .multiselect-dropdown {
    .dropdown-btn {
      .selected-item-container {
        width: 100%;
        .selected-item {
          max-width: 92% !important;
          span {
            width: 100%;
            font-weight: 500 !important;
          }
        }
      }
    }
    .dropdown-list {
      .filter-textbox {
        left: 20px;
        top: 0;
      }
      .multiselect-item-checkbox input + div {
        padding-left: 10px !important;
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
}

// multiple items selection - no bg for selected items
.multi-select {
  .multiselect-dropdown {
    background: #fff;
    .dropdown-btn {
      .selected-item-container {
        max-width: 80%;
        .selected-item {
          padding-left: 0 !important;
          background: none !important;
          border: none !important;
          line-height: 20px;
          box-shadow: none;
          max-width: 100% !important;
          margin-right: 0 !important;
          span {
            color: #415960 !important;
            width: 100%;
            &:first-child {
              font-weight: 500 !important;
              padding-right: 0 !important;
              font-size: 11px !important;
            }
          }
        }
      }
    }
  }
}

// list filter dropdowns
.multi-select-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  border: 0.5px solid #9fabae;
  border-radius: 3px;
  padding-left: 10px;
  z-index: 1;
  .select-name {
    position: absolute;
    color: #415960;
    font-weight: 500;
    background: #fff;
    font-size: 11px;
    top: 6px;
    max-width:60%;
  }
  .multiselect-dropdown {
    &::after {
      content: "";
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      left: 10%;
      transform: translateX(-50%);
      top: -8px;
      z-index: 9999;
    }
  }
  .dropdown-btn {
    height: 26px;
    span:first-child {
      color: #415960;
      font-weight: 500;
    }
    .selected-item span {
      font-weight: 300;
    }
  }
  .dropdown-list {
    width: 224px !important;
    right: 0;
    bottom: calc(100% + 8px) !important;
  }
}

// multi select - done
button {
  &.done-button {
    position: absolute;
    top: -2px;
    right: 5px;
    font-size: 11px;
    color: #417fa8;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2px;
  }
}

.error-dropdown {
  .multiselect-dropdown {
    .dropdown-btn {
      border: 0.5px solid #c46448 !important;
    }
  }
}

// reduced multi select dropdown
.sm {
  .multiselect-dropdown {
    .dropdown-btn {
      height: 26px;
      span:first-child {
        vertical-align: middle;
        margin-top: -3px;
      }
      .selected-item {
        line-height: 18px !important;
        span {
          margin-top: 6.5px;
        }
      }
    }
  }
}

.no-search {
  .multiselect-dropdown {
    .dropdown-list .item1 .multiselect-item-checkbox input[type="checkbox"] + div {
      left: 0;
      top: 0;
      color: #415960 !important;
      right: unset;
      justify-content: start;
      font-size: 12px;
      &::before {
        left: 0 !important;
        top: 9px !important;
      }
      &:after {
        left: 2px !important;
        top: 8px !important;
      }
    }
  }
}
