.status {
  height: 16px;
  border-radius: 2px;
  font-size: 11px;
  padding: 0 8px;
  width: fit-content;
  line-height: 15px;
  font-weight: 500;
  &.lg {
    height: 20px;
    padding: 2px 10px;
  }

  // active
  &.active {
    border: 0.5px solid #75a762;
    color: #75a762;
    background: rgb(223 235 218);

    &.tooltip-active {
      color: #fff;
      background: #54953c;
      border: 0.5px solid #54953c;
    }
  }

  &.closed {
    border: 0.5px solid #e3e9da;
    color: #565a54;
    background: rgb(223 235 218);

    &.tooltip-closed {
      color: #fff;
      background: #8c8989;
      border: none;
    }
  }

  // draft
  &.draft {
    border: 0.5px solid #4582aa;
    color: #4582aa;
    background: #e3ecf2;
  }

  // inprogress / pending
  &.inprogress {
    color: #b18117;
    border: 0.25px solid #e29e06;
    background: #e29e0626;
  }

  // inactive / terminated
  &.inactive {
    color: #bf6060;
    border: 0.25px solid #d76f6f;
    background: #d76f6f40;
    &.tooltip-inactive {
      color: #fff;
      background: #bf6060;
      border: 0.5px solid #bf6060;
    }
  }

  //submitted
  &.submitted {
    color: #4582aa;
    border: #4582aa, 2px, 0.25px;
    background: #e3ecf2;
  }

  &.cancelled {
    color: #939191;
    border: 2px solid #cbcbcb;
    border-radius: 0.25px;

    background: #cbcbcb;
    position: relative;
    padding-left: 15px;

    &::before {
      position: absolute;
      content: "";
      background: url("../assets/images/cancelled-icon.png") no-repeat;
      width: 10px;
      height: 10px;
      background-size: contain;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.overdue {
    color: #d76f6f;
    border: 0.25px solid #d76f6f;
    border-radius: 2px;
    background: #d76f6f40;
    .icon-tick::before {
      color: #d76f6f;
      font-size: 10px;
    }
  }

  &.completed,
  &.resolved {
    color: #5c8b4b;
    border: 0.25px solid #89b479;
    border-radius: 2px;
    background: #e4f1df;

    .iconkraios-converted-case:before {
      color: #5c8b4b;
      vertical-align: middle;
    }
  }

  &.round {
    border-radius: 20px;
  }
}
