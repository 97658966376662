.modal {
    display: block;
    background: #00000099;
    overflow: hidden;
    .modal-dialog {
        height: 100vh;
        width: 100vw;
        .modal-content {
            border-radius: 5px;
            padding: 28px 20px;
            height: 90vh;
            width: 97vw;
            left: 50%;
            transform: translateX(-50%);
            transform: translate(-50%, -50%);
            top: 46%;
            .modal-header {
                border-bottom: none;
                padding: 0;
                margin-bottom: 20px;
                align-items: baseline;
                justify-content: space-between;
                &.shadow {
                    box-shadow: 0 0 18px #00000016;
                    padding: 10px 20px;
                }
                .icon-close {
                    font-weight: bolder;
                    font-size: 12px;
                }
                .modal-title {
                    color: #415960;
                    font-weight: 500;
                    font-size: 14px;
                }
                .sub-title {
                    color: #415960;
                    font-weight: 300;
                    font-size: 12px;
                }
            }
            .modal-body {
                padding: 0;
            }
            .modal-footer {
                margin-top: 17px;
                padding: 0;
                border-top: none;
                justify-content: start;
                &.shadow {
                    box-shadow: 0 0 18px #00000016 !important;
                    padding: 5px 15px;
                }
                button {
                    font-size: 12px;
                    height: 26px;
                    border: 0.5px solid #415960;
                    padding: 4px 16px;
                }
                .btn-primary {
                    background: #415960;
                    color: #fff;
                }
                .btn-outline {
                    background: #fff;
                    color: #415960 !important;
                    i:before{
                      color: #415960;
                    }
                }
            }
            &.sm {
                width: 320px;
                height: auto;
            }
        }
    }

    // one modal inside another
    &.inside-modal {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: -104px;
        left: -40px;
        @media screen and (min-width: 1400px) {
            left: -43px;
        }
        @media screen and (min-width: 1920px) {
            left: -50px;
            top: -107px;
        }
    }
}
