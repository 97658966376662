ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

p {
    margin-bottom: 0;
}

.w50 {
    width: 50% !important;
}

.w100 {
    width: 100% !important;
}

.w75 {
    width: 75% !important;
}

.mw100 {
    max-width: 100% !important;
}

.h100 {
    height: 100% !important;
}

input,
textarea {
    outline: none !important;
    border: 0.5px solid #9fabae;
    height: 24px;
    font-size: 12px;
    color: #415960 !important;
    width: 100%;
    padding: 0 8.5px;
    font-weight: 400;
    font-style: normal !important;

    &::placeholder {
        font-size: 12px;
        color: #415960;
        font-weight: 300;
    }

    &.error {
        border: 0.5px solid #c46448 !important;
    }
}

.number {
    width: 62px;
    border: 0.5px solid #9fabae;
    border-radius: 2px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 5px;

    input {
        border: none;
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        width: calc(100% - 10px);
        margin-right: 5px;
    }
}

textarea {
    resize: none;
    height: 100px !important;
    outline: none;
    padding: 5px 8.5px !important;
}

.title {
    font-size: 14px;
    color: #415960;
    font-weight: 400;
}

.textoverflow {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    max-width: 100%;
    vertical-align: middle;
}

.cp {
    cursor: pointer;
}

.cd {
    cursor: default !important;
}

.note {
    color: #415960;
    font-size: 11px;
    font-weight: 300;
}

.no-data {
    font-size: 12px;
    font-weight: 400;
    color: #415960;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;

    &.no-opacity {
        opacity: 1;
    }
}

.fnt-md {
    font-weight: 500 !important;
}

.fnt-reg {
    font-weight: 400 !important;
}

.fnt-light {
    font-weight: 300 !important;
}

.flex-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.asterik {
    color: #e71a18;
    font-size: 11px;
    font-weight: 500;
}

.global-nav-header {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    top: 101px;
    white-space: nowrap;

    li {
        margin-right: 48px;
        color: #415960;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        &.active {
            color: #00aca4;
            font-size: 20px;
            font-weight: 500;
            cursor: default;
            pointer-events: none;

            &.disabled {
                opacity: 1;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.pill {
    background: #415960;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    height: 15px;
    padding: 1.6px 7.5px 0.7px 7.5px;
    display: inline-block;
    line-height: 12px;

    &.min-pill {
        padding: 1.6px 0;
        width: 28px;
        text-align: center;
    }

    @media screen and (min-width: 1400px) {
        line-height: 13px;
    }

    &.md {
        padding: 2.5px 10px;
        font-size: 11px;
        height: 18px;
    }

    &.outline {
        background: #fff;
        border: 0.5px solid #70707080;
        color: #767b87;
        height: 18px;
        line-height: 14px;
    }

    &.light {
        background: #41596012;
        color: #417fa8;
        min-height: 18px;
        line-height: 18px;
        height: auto;
        border-radius: 10px;

        a {
            color: #417fa8;
            text-decoration: none;
        }
    }

    i {
        cursor: pointer;
        font-size: 6px;
        padding-left: 5px;
        display: inline-block;
        vertical-align: middle;
    }
}

.error {
    color: #ff0000;
    font-style: italic;
    font-size: 11px;
    margin-top: 2px;
}

.gray-bg {
    background: #f4f4f480;
}

.info {
    color: #415960;
    font-size: 12px;
    font-weight: 300;
}

.records-length {
    font-size: 12px;
    color: #6b6f78;
    font-weight: 400;

    strong {
        font-weight: 500;
    }
}

.restrict-overlay {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    bottom: -35px;
}

.footer-tabs {
    li {
        position: relative;
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        margin-right: 30px;
        text-align: center;
        color: #415960;
        display: flex;
        flex-direction: column;

        i {
            margin-bottom: 2px;
            font-size: 17px;
        }

        img {
            width: 18px;
            margin: auto;
            margin-bottom: 2px;
        }

        &.active {
            cursor: default;
            font-weight: 500;

            i::before {
                color: #00aca4;
            }

            &::before {
                content: "";
                position: absolute;
                height: 3px;
                background: #00aca4;
                width: 100%;
                top: -10px;
            }
        }
    }
}

// title with full width line
.title-with-hr {
    position: relative;
    display: flex;
    align-items: center;

    .title {
        width: fit-content;
        background: #fff;
        position: relative;
        padding: 0 10px;
        font-size: 12px;
        color: #767b87;
        font-weight: 400;
    }

    .btn-link {
        background: #fff;
        z-index: 0;
        font-weight: 400;
    }

    &::before {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        border-bottom: 0.5px solid #d6d6d680;
        z-index: 0;
        top: 11px;
    }

    &.d-block::before {
        top: 8px;
    }
}

// black popup for list
.hover-tooltip {
    position: absolute;
    background: #333;
    color: #fff;
    min-width: 130px;
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
    border-radius: 5px;
    top: 19px;
    z-index: 1;
    overflow-y: auto;

    .tooltip-item {
        word-break: break-all;
        line-height: 15px;
        margin-bottom: 6px;
    }
}

.up-arrow {
    &::before {
        position: absolute;
        content: "";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #333;
        bottom: -5px;
        right: 6px;
    }
}

.char-info {
    color: #415960;
    font-size: 11px;
    font-weight: 300;

    strong {
        font-weight: 500;
    }
}