$icomoon-font-family: "cobalt" !default;
$icomoon-font-path: "fonts" !default;

$icon-grey-smiley-path1: unquote('"\\e90d"');
$icon-grey-smiley-path2: unquote('"\\e918"');
$icon-grey-smiley-path3: unquote('"\\e91e"');
$icon-grey-smiley-path4: unquote('"\\e91f"');
$icon-grey-smiley-path5: unquote('"\\e928"');
$icon-grey-smiley-path6: unquote('"\\e929"');
$icon-green-smiley-path1: unquote('"\\e93b"');
$icon-green-smiley-path2: unquote('"\\e93c"');
$icon-green-smiley-path3: unquote('"\\e93d"');
$icon-green-smiley-path4: unquote('"\\e947"');
$icon-green-smiley-path5: unquote('"\\e948"');
$icon-green-smiley-path6: unquote('"\\e94e"');
$icon-red-smiley-path1: unquote('"\\e94f"');
$icon-red-smiley-path2: unquote('"\\e955"');
$icon-red-smiley-path3: unquote('"\\e956"');
$icon-red-smiley-path4: unquote('"\\e957"');
$icon-red-smiley-path5: unquote('"\\e958"');
$icon-yellow-smiley-path1: unquote('"\\e959"');
$icon-yellow-smiley-path2: unquote('"\\e95a"');
$icon-yellow-smiley-path3: unquote('"\\e95b"');
$icon-yellow-smiley-path4: unquote('"\\e95c"');
$icon-yellow-smiley-path5: unquote('"\\e95d"');
$icon-business: unquote('"\\e95e"');
$icon-admin: unquote('"\\e95f"');
$icon-master-data: unquote('"\\e960"');
$icon-completed-path1: unquote('"\\e961"');
$icon-completed-path2: unquote('"\\e962"');
$icon-closed-path1: unquote('"\\e916"');
$icon-closed-path2: unquote('"\\e917"');
$icon-calendar: unquote('"\\e900"');
$icon-net-pay: unquote('"\\e901"');
$icon-workflow: unquote('"\\e902"');
$icon-world: unquote('"\\e903"');
$icon-circle-tick: unquote('"\\e904"');
$icon-solid-tick-path1: unquote('"\\e905"');
$icon-solid-tick-path2: unquote('"\\e906"');
$icon-user-girl-path1: unquote('"\\e907"');
$icon-user-girl-path2: unquote('"\\e908"');
$icon-user-profile-path1: unquote('"\\e909"');
$icon-user-profile-path2: unquote('"\\e90a"');
$icon-viewer: unquote('"\\e90b"');
$icon-documents: unquote('"\\e90c"');
$icon-search: unquote('"\\e90e"');
$icon-send: unquote('"\\e90f"');
$icon-sort: unquote('"\\e910"');
$icon-sort-asc-path1: unquote('"\\e911"');
$icon-sort-asc-path2: unquote('"\\e912"');
$icon-sort-desc-path1: unquote('"\\e913"');
$icon-sort-desc-path2: unquote('"\\e914"');
$icon-statistics: unquote('"\\e915"');
$icon-right-arrow: unquote('"\\e919"');
$icon-run: unquote('"\\e91a"');
$icon-entities: unquote('"\\e91b"');
$icon-national-id: unquote('"\\e91c"');
$icon-open-lock: unquote('"\\e91d"');
$icon-padlock: unquote('"\\e920"');
$icon-pencil: unquote('"\\e921"');
$icon-phone: unquote('"\\e922"');
$icon-plus: unquote('"\\e923"');
$icon-tick: unquote('"\\e924"');
$icon-gross-pay: unquote('"\\e925"');
$icon-home: unquote('"\\e926"');
$icon-info: unquote('"\\e927"');
$icon-job-band: unquote('"\\e92a"');
$icon-job-detail: unquote('"\\e92b"');
$icon-link: unquote('"\\e92c"');
$icon-logout: unquote('"\\e92d"');
$icon-logout-hover: unquote('"\\e92e"');
$icon-options: unquote('"\\e92f"');
$icon-chat: unquote('"\\e930"');
$icon-chat-active-path1: unquote('"\\e931"');
$icon-chat-active-path2: unquote('"\\e932"');
$icon-chat-active-path3: unquote('"\\e933"');
$icon-chat-active-path4: unquote('"\\e934"');
$icon-circle-close: unquote('"\\e935"');
$icon-close: unquote('"\\e936"');
$icon-create: unquote('"\\e937"');
$icon-domino: unquote('"\\e938"');
$icon-flash: unquote('"\\e939"');
$icon-flash-active: unquote('"\\e93a"');
$icon-solid-minus-path1: unquote('"\\e93e"');
$icon-solid-minus-path2: unquote('"\\e93f"');
$icon-export: unquote('"\\e940"');
$icon-family: unquote('"\\e941"');
$icon-gear: unquote('"\\e942"');
$icon-group: unquote('"\\e943"');
$icon-save: unquote('"\\e944"');
$icon-edit: unquote('"\\e945"');
$icon-error-alert: unquote('"\\e946"');
$icon-copy: unquote('"\\e949"');
$icon-down-arrow: unquote('"\\e94a"');
$icon-briefcase: unquote('"\\e94b"');
$icon-clipboard: unquote('"\\e94c"');
$icon-clock: unquote('"\\e94d"');
$icon-address: unquote('"\\e950"');
$icon-arrow: unquote('"\\e951"');
$icon-avatar: unquote('"\\e952"');
$icon-bank: unquote('"\\e953"');
$icon-bin: unquote('"\\e954"');
$white: #ffffff;
$tab-text: #415960;
$nuetral-grey-md: #ebebeb;
$RAG-status-green: #75a762;
$tab-text: #415960;
$overdue: #d00801;
$form-text: #333333;
$nuetral-grey-light: #f7f7f7;
$form-border-dark: #707070;


//fonts
$font-12: 12px;
$font-11: 11px;
$font-10: 10px;
$fnt-md: 500;
$fnt-reg: 400;
$fnt-light: 300;