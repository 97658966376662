.ng-select {
  .ng-select-container {
    border: 0.5px solid #9fabae;
    height: 24px;
  }
  .ng-arrow-wrapper {
    margin-right: 6px;
    //top: -2px;
    top: -5px;
    .ng-arrow {
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #415960;
    }
  }
  .ng-dropdown-panel {
    box-shadow: 1px 1px 20px #00000014;
    background: #fff;
    padding: 10px 0;
    line-height: 13px;
    width: calc(100% - 5px);
    left: 2px;
    .ng-option {
      padding: 6px 23px;
      font-weight: 300;
      white-space: normal !important;
      &.ng-option-marked {
        background: #417fa815;
      }
      &.ng-option-selected {
        font-weight: 500;
      }
      &.ng-option-disabled {
        text-align: center;
      }
    }
    .ng-dropdown-panel-items {
      max-height: 70px;
    }
  }
  .ng-value-container {
    padding-left: 8px;
  }
  .ng-dropdown-panel,
  .ng-value {
    font-size: 12px;
    color: #415960;
    font-weight: 400;
  }
  .ng-value {
    //margin: 4.2px 0;
    margin: 3px 0;
    display: inline-block;
  }
  .ng-input {
    padding-left: 8px;
  }
  .ng-placeholder {
    font-size: 12px;
    color: #415960;
    font-weight: 300;
    padding: 4px 0;
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  &.error-select {
    .ng-select-container {
      border: 0.5px solid #c46448;
    }
  }

  &.ng-select-opened {
    .ng-arrow {
      transform: rotate(180deg);
    }
  }

  &.lg {
    .ng-dropdown-panel-items {
      max-height: 180px;
    }
  }
  &.md {
    .ng-dropdown-panel-items {
      max-height: 130px;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}

// with search icon
.search-select {
  .ng-value-container,
  .ng-input {
    padding-left: 22px;
  }
}

// table template
.table-select {
  .table {
    margin-bottom: 0;
  }
  .ng-dropdown-panel {
    width: 380px;
    padding-left: 10px;
  }
  .ng-option {
    margin-bottom: 0 !important;
    padding: 0 !important;
    &:nth-child(even) tbody tr td {
      --bs-table-bg-type: #fff !important;
    }
  }
}

.position-top {
  .ng-dropdown-panel {
    top: unset;
    bottom: 25px;
  }
}

.xs {
  .ng-option {
    padding: 5px 0 !important;
    padding-left: 5px !important;
  }
}
