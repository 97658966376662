.associated-docs {
    min-width: 250px;
    max-width: 250px;

    ul {
        li {
            border-bottom: 0.5px solid #d6d6d680;
            color: #417fa8;
            font-size: 12px;
            font-weight: 500;
            padding: 15px 10px;
            cursor: pointer;
        }
    }
}