.side-bar {
    background: #f4f6f6;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    padding: 25px 0;
    width: 223px;
    height: 100%;
    margin-right: 30px;

    li {
        color: #417fa8;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: "";
            display: inline-block;
        }

        &.current {
            color: #415960;
            font-weight: 500;
            pointer-events: none;
        }
    }

    &.plain {
        width: 210px;
        overflow-y: auto;
        padding: 15px 0;
        margin-right: 10px;
        li {
            padding-left: 15px;
            font-weight: 300;
            span {
                display: inline-block;
                max-width: 80%;
            }
            &.current {
                position: relative;
                span {
                    font-weight: 500 !important;
                }
                &::before {
                    left: 0;
                    top: 50%;
                    width: 2px;
                    height: 13px;
                    background: #417fa8;
                    transform: translateY(-50%);
                    border-radius: 0;
                    border: none;
                }
                &::after {
                    background: url("../assets/images/right-arrow.png") no-repeat;
                    background-size: contain;
                    width: 11px;
                    height: 11px;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: unset;
                }
            }
        }
    }

    &.bulletted {
        li {
            &::before {
                border-radius: 50%;
                border: 1px solid #e5e5e5;
                width: 16px;
                height: 16px;
                left: -8px;
                background: #fff;
            }

            &.current::after {
                background: #415960;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                left: -4px;
                top: 4px;
            }
        }
    }

    &.tiled {
        background: #fff;
        padding: 0;
        border: none;
        border-right: 1px solid #e8e8e8;
        margin-right: 18px;
        li {
            height: 35px;
            border-bottom: 0.5px solid #70707050;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            padding-left: 18px;
            i {
                width: 16px;
                text-align: center;
                margin-right: 10px;
                font-size: 14px;
                &::before {
                    color: #00aca4;
                }
            }
            &::before {
                display: none;
            }
            &.current {
                background: #415960;
                color: #fff !important;
                i:before {
                    color: #fff !important;
                }
                &::after {
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-right: 6px solid #fff;
                    width: auto;
                    height: auto;
                    border-radius: 0;
                    left: unset;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &.disabled {
                color: #415960;
                i:before {
                    color: #415960;
                }
                .asterik {
                    display: none;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }

        // all tiles are horizontally aligned
        &.inline {
            display: flex;
            margin-right: 0;
            width: 100%;
            border-radius: 5px 5px 0 0 !important;
            justify-content: space-between;
            li {
                border: none;
                padding: 0 10px;
                white-space: nowrap;
                i {
                    margin-right: 6px;
                }
                &::after {
                    left: 50%;
                    bottom: -3px;
                    top: unset;
                    transform: rotate(90deg);
                    right: unset;
                }
            }
        }
    }
}
