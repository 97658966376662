table {
    tbody {
        .edit-row {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            border-left-color: #417fa8 !important;
            border-left-style: solid !important;

            td {
                position: relative;
                border: none;
                z-index: 3;

                .input-container {
                    line-height: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 10px;
                    right: 0;

                    .border-error {
                        border: 0.5px solid #c46448;
                    }
                }
            }
        }
    }
}
