/* You can add global styles to this file, and also import other style files */
@import "~typeface-roboto/index.css";
@import "./icons";
@import "./common";
@import "./card";
@import "./dropdown";
@import "./table";
@import "./button";
@import "./form";
@import "./modal";
@import "./muti-select-dropdown";
@import "./toastr";
@import "./popup";
@import "./radio";
@import "./calendar";
@import "./ng-select";
@import "./tabs";
@import "./grid";
@import "./status";
@import "./phone_code";
@import "./comments_history";
@import "./associated_docs";
@import "./stage";
@import "./side_bar";
@import "./custom_calendar";
@import "./table_edit";
@import "./charts";
@import "./input_number.scss";

body {
  padding: 0;
  margin: 0;
  border: none;
  font-family: "Roboto", sans-serif !important;
  background: url("../assets/images/bg-horizon@2x.png") center center / 100% 100%;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-color: #efefef;
}

// selector
::selection {
  background: #000000;
  color: #ffffff;
}

::-moz-selection {
  background: #000000;
  color: #ffffff;
}

// scroll bar
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 100px;
}

// ngb tooltip
ngb-tooltip-window {
  font-size: 11px !important;
  font-family: "Roboto", sans-serif !important;
  max-width: 500px;
  --bs-tooltip-max-width: 100% !important;

  &.fade {
    opacity: 1 !important;
  }

  .tooltip-arrow {
    --bs-tooltip-bg: #333;
  }

  .tooltip-inner {
    border-radius: 2px;
    text-align: left !important;
    background: #333333;
  }

  &.br-10 {
    .tooltip-inner {
      border-radius: 10px;
    }
  }

  &.nowrap {
    .tooltip-inner {
      white-space: nowrap;
    }
  }

  &.md {
    width: 200px;
  }
}
