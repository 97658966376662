.comments-history {
    ul {
        font-size: 12px;
        color: #415960;
        overflow: auto;
        padding-right: 2px;

        li {
            display: flex;
            margin-bottom: 10px;

            .icon-user-profile {
                font-size: 37px;
                margin-right: 10px;
            }

            .comment-info {
                padding-bottom: 10px;
                width: 100%;
                border-bottom: 0.5px solid #d6d6d680;

                .user {
                    font-weight: 500;
                }

                .date {
                    color: #6b6f78;
                    font-weight: 300;
                }

                p {
                    font-weight: 400;
                }

                .comment {

                    ul,
                    ol {
                        li {
                            display: list-item !important;
                            list-style: inherit !important;
                            margin-left: 20px !important;
                        }
                    }

                    .fr-view table td,
                    .fr-view table th {
                        border: 1px solid #DDDDDD;
                    }

                    .fr-view table td:empty,
                    .fr-view table th:empty {
                        height: 20px;
                    }

                    table {
                        border: none;
                        border-collapse: collapse;
                        empty-cells: show;
                        max-width: 100%;
                        table-layout: fixed;
                        margin-top: 0px !important;
                    }

                    table td {
                        min-width: 5px;
                        border: 1px solid #DDDDDD;
                    }

                    table th {
                        background: #ECECEC;
                        text-align: center;
                    }

                    table.fr-dashed-borders td,
                    table.fr-dashed-borders th {
                        border: 1px dashed #000000;
                    }

                    table.fr-alternate-rows tbody tr:nth-child(2n) {
                        background-color: #F5F5F5;
                    }

                    table td.fr-highlighted,
                    table th.fr-highlighted {
                        border: 1px solid #FF0000;
                    }

                    table td.fr-thick,
                    table th.fr-thick {
                        border-style: solid;
                        border-width: 1px;
                    }

                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}