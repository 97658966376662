.insights-charts {
    display: flex;
    overflow-x: hidden;

    .insights-left-chart {
        width: 350px;
        float: left;
    }

    .insights-right-chart {
        border-left: 1px solid #dbdbdb80;
        float: left;
        width: calc(100% - 380px);
        padding-left: 20px;
        margin-left: 30px;
        position: relative;
    }

    .chart-container {
        overflow: hidden;
        display: inline-block;
        position: relative;
    }
}

.status-groups {
    font-size: 12px;
    align-items: center;
    display: flex;

    .legend {
        padding: 5px;

        img {
            margin: 5px;
            height: 15px;
        }
    }
}